<!-- 人才详情 -->
<template>
  <div>
    <div>
      <v-btn
        class="ml-2 fl"
        @click="back()"
        :disabled="isEditPersonalInformation"
      >
        返回上一级
      </v-btn>

      <v-btn
        class="ml-2 fl"
        @click="workExperience = true"
        :disabled="isEditPersonalInformation"
      >
        工作经历
      </v-btn>

      <v-btn
        class="ml-2 fl"
        @click="policyExperience = true"
        :disabled="isEditPersonalInformation"
      >
        政策申请经历
      </v-btn>

      <v-btn
        class="ml-2 fl"
        @click="openEditPersonalInformation"
        :disabled="isEditPersonalInformation"
      >
        编辑
      </v-btn>

      <el-breadcrumb separator-class="el-icon-arrow-right" class="fr nav">
        <el-breadcrumb-item><a @click="getHome">首页</a></el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/selectionlist' }"
          >人才认证管理</el-breadcrumb-item
        >
        <el-breadcrumb-item>人才详情</el-breadcrumb-item>
      </el-breadcrumb>
    </div>

    <div style="clear: both;height: 16px"></div>

    <v-row v-if="isEditPersonalInformation">
      <v-col cols="9">
        <v-card flat>
          <v-card-text class="text-content">
            <el-form
              :model="personalInformation"
              ref="personalInformation"
              label-width="auto"
              style="width: 100%;"
            >
              <v-row>
                <v-col cols="3">
                  <span>姓名:</span>
                  <input
                    class="input-style"
                    v-model="personalInformation.name"
                  />
                </v-col>
                <v-col cols="3">
                  <span>出生年月:</span>
                  <v-menu
                    offset-y
                    v-model="displayMenuBirthdayDate"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-btn small depressed class="ml-2" v-on="on">
                        <v-icon small>event</v-icon>
                        {{ birthdayDate }}
                      </v-btn>
                    </template>
                    <v-date-picker
                      first-day-of-week="1"
                      color="green lighten-1"
                      v-model="personalInformation.birthday"
                      :show-current="false"
                    >
                      <v-spacer />
                      <v-btn
                        text
                        small
                        @click="onIsSaveBirthdayDateCondition(0)"
                        >取消</v-btn
                      >
                      <v-btn
                        text
                        small
                        @click="onIsSaveBirthdayDateCondition(1)"
                        >确定</v-btn
                      >
                    </v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="3">
                  <span>性别:</span>
                  <el-radio-group
                    v-model="personalInformation.sex"
                    class="ml-4"
                  >
                    <el-radio :label="0">男</el-radio>
                    <el-radio :label="1">女</el-radio>
                  </el-radio-group>
                </v-col>
                <v-col cols="3">
                  <span>国籍:</span>
                  <select
                    class="select-style"
                    v-model="personalInformation.citizenship"
                  >
                    <option
                      v-for="item in choiceNationality"
                      :key="item.name"
                      v-html="item.name"
                    >
                    </option>
                  </select>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="3"
                  v-if="personalInformation.citizenship === '中国'"
                >
                  <span>民族:</span>
                  <select
                    class="select-style"
                    v-model="personalInformation.nation"
                  >
                    <option
                      v-for="item in nation"
                      :key="item.nation"
                      v-html="item.nation"
                    >
                    </option>
                  </select>
                </v-col>
                <v-col cols="3">
                  <span>联系电话:</span>
                  <input
                    class="input-style"
                    v-model="personalInformation.phone"
                  />
                </v-col>
                <v-col cols="3">
                  <span>邮箱:</span>
                  <input
                    class="input-style"
                    v-model="personalInformation.email"
                  />
                </v-col>
                <v-col
                  cols="3"
                  v-if="personalInformation.citizenship === '中国'"
                >
                  <span>籍贯:</span>
                  <input
                    class="input-style"
                    v-model="personalInformation.birthplace"
                  />
                </v-col>
                <v-col cols="3">
                  <span>证件类型:</span>
                  <select
                    class="select-style"
                    v-model="personalInformation.identityType"
                  >
                    <option
                      v-for="item in documentType"
                      :key="item"
                      v-html="item"
                    ></option>
                  </select>
                </v-col>
                <v-col cols="3">
                  <span>证件编号:</span>
                  <input
                    class="input-style"
                    v-model="personalInformation.identityCode"
                  />
                </v-col>
                <v-col cols="3">
                  <span>证件开始有效日期:</span>
                  <v-menu
                    offset-y
                    v-model="displayMenuConditionStartDate"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-btn small depressed class="ml-2" v-on="on">
                        <v-icon small>event</v-icon>
                        {{ startDate }}
                      </v-btn>
                    </template>
                    <v-date-picker
                      first-day-of-week="1"
                      color="green lighten-1"
                      v-model="personalInformation.startEffectiveDate"
                      :show-current="false"
                    >
                      <v-spacer />
                      <v-btn text small @click="onIsSaveIDDateCondition(0)"
                        >取消</v-btn
                      >
                      <v-btn text small @click="onIsSaveIDDateCondition(1)"
                        >确定</v-btn
                      >
                    </v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="3">
                  <span>证件结束有效日期:</span>
                  <v-menu
                    offset-y
                    v-model="displayMenuConditionEndDate"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-btn small depressed class="ml-2" v-on="on">
                        <v-icon small>event</v-icon>
                        {{ endDate }}
                      </v-btn>
                    </template>
                    <v-date-picker
                      first-day-of-week="1"
                      color="green lighten-1"
                      v-model="personalInformation.endEffectiveDate"
                      :show-current="false"
                    >
                      <v-spacer />
                      <v-btn text small @click="onIsSaveIDDateCondition(2)"
                        >取消</v-btn
                      >
                      <v-btn text small @click="onIsSaveIDDateCondition(3)"
                        >确定</v-btn
                      >
                    </v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <span>户籍住址:</span>
                  <el-cascader
                    class="ml-2 address-style"
                    size="small"
                    v-model="personalInformation.addresses"
                    @change="handleAddresses"
                    :options="permanentAddress"
                    :placeholder="personalInformation.address"
                  >
                  </el-cascader>
                  <input
                    class="input-style"
                    placeholder="请输入详细地址"
                    v-model="personalInformation.details"
                  />
                </v-col>
              </v-row>
            </el-form>
          </v-card-text>
        </v-card>

        <div class="mt-4"></div>

        <!-- 工作履历 -->
        <v-card flat>
          <div class="authentication-information">工作履历</div>
          <v-divider class="ml-4 mr-4"></v-divider>
          <v-card-text class="text-content">
            <el-form
              :model="personalInformation"
              ref="personalInformation"
              label-width="auto"
              style="width: 100%;"
            >
              <v-row
                v-for="(item, index) in personalInformation.resumes"
                :key="item.id"
              >
                <v-col cols="5">
                  <span>工作单位名称:</span>
                  <input class="input-style" v-model="item.companyName" />
                </v-col>
                <v-col cols="5">
                  <span>职位:</span>
                  <input class="input-style" v-model="item.jobTitle" />
                </v-col>
                <v-col cols="5">
                  <span>开始年月:</span>
                  <el-date-picker
                    class="ml-2"
                    style="width: 160px"
                    size="small"
                    v-model="item.startDate"
                    type="date"
                    placeholder="开始年月"
                    value-format="yyyy-MM-dd"
                  >
                  </el-date-picker>
                </v-col>
                <v-col cols="5">
                  <span>结束年月:</span>
                  <el-date-picker
                    class="ml-2"
                    style="width: 160px"
                    size="small"
                    v-model="item.endDate"
                    type="date"
                    placeholder="开始年月"
                    value-format="yyyy-MM-dd"
                  >
                  </el-date-picker>
                </v-col>
                <v-col cols="1" :offset="1">
                  <v-btn
                    color="error"
                    class="mr-8"
                    small
                    @click="onDeleteWorkResume(item, index)"
                  >
                    <v-icon small>delete</v-icon>
                  </v-btn>
                </v-col>

                <v-divider class="ml-4 mr-4"></v-divider>
              </v-row>
            </el-form>
          </v-card-text>

          <v-card-text>
            <v-btn text @click="onAddWorkResume">
              <v-icon small class="mr-1" color="#1090e7">add</v-icon>
              添加工作履历
            </v-btn>
          </v-card-text>
        </v-card>

        <div class="mt-4"></div>

        <!-- 目前就职 -->
        <v-card flat>
          <v-card-text class="text-content">
            <el-form
              :model="personalInformation"
              ref="personalInformation"
              label-width="auto"
              style="width: 100%;"
            >
              <v-row>
                <v-col cols="4">
                  <span>目前供职公司:</span>
                  <select
                    class="select-style"
                    @change="getEnterpriseId"
                    v-model="personalInformation.enterpriseName"
                  >
                    <option
                      v-for="item in enterpriseName"
                      :key="item.id"
                      v-html="item.name"
                    ></option>
                  </select>
                </v-col>
                <v-col cols="4">
                  <span>所在单位职务:</span>
                  <input
                    class="input-style"
                    v-model="personalInformation.talentOccupation.position"
                  />
                </v-col>
                <v-col cols="4">
                  <span>职称类别:</span>
                  <select
                    class="select-style"
                    v-model="personalInformation.talentOccupation.rankCategory"
                  >
                    <option
                      v-for="item in optionsCertificate"
                      :key="item.label"
                      v-html="item.value"
                    ></option>
                  </select>
                </v-col>
                <v-col cols="4">
                  <span>是否省外引进:</span>
                  <select
                    class="select-style"
                    v-model="personalInformation.talentOccupation.rankLevel"
                  >
                    <option
                      v-for="item in introduce"
                      :key="item.number"
                      v-html="item.value"
                    ></option>
                  </select>
                </v-col>
                <v-col cols="4">
                  <span>从事专业领域:</span>
                  <select
                    class="select-style"
                    v-model="personalInformation.talentOccupation.skillCategory"
                  >
                    <option
                      v-for="item in professionalField"
                      :key="item.value"
                      v-html="item.value"
                    ></option>
                  </select>
                </v-col>
                <v-col cols="4">
                  <span>入职时间:</span>
                  <v-menu
                    offset-y
                    v-model="displayMenuEntryDate"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-btn small depressed class="ml-2" v-on="on">
                        <v-icon small>event</v-icon>
                        {{ entryDate }}
                      </v-btn>
                    </template>
                    <v-date-picker
                      first-day-of-week="1"
                      color="green lighten-1"
                      v-model="personalInformation.talentOccupation.hireDate"
                      :show-current="false"
                    >
                      <v-spacer />
                      <v-btn text small @click="onIsSaveEntryDateCondition(0)"
                        >取消</v-btn
                      >
                      <v-btn text small @click="onIsSaveEntryDateCondition(1)"
                        >确定</v-btn
                      >
                    </v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>

              <v-divider></v-divider>

              <v-row>
                <v-col cols="4">
                  <v-card-actions class="file-card-height">
                    <el-form
                      :model="personalInformation"
                      ref="enterpriseInformation"
                      label-width="auto"
                      style="width: 100%;"
                    >
                      <el-form-item class="file-card">
                        <el-upload
                          class="upload-demo"
                          accept=".pdf"
                          :action="url + '/file'"
                          :on-success="uploadSuccessFile"
                          :before-upload="uploadBeforeFDFFile"
                          :on-remove="handleRemove"
                          :before-remove="beforeRemove"
                          :on-preview="handlePreview"
                          :headers="importHeaders"
                          :file-list="laborContractFile"
                          show-file-list
                        >
                          <div class="file-name">
                            <span style="color: red">* </span>劳动合同
                          </div>
                          <el-button
                            size="small"
                            round
                            class="file-button"
                            @click.native="setLabelMemo('劳动合同')"
                            >点击上传
                          </el-button>
                        </el-upload>
                      </el-form-item>
                    </el-form>
                  </v-card-actions>
                </v-col>
                <v-col cols="4">
                  <v-card-actions class="file-card-height">
                    <el-form
                      :model="personalInformation"
                      ref="enterpriseInformation"
                      label-width="auto"
                      style="width: 100%;"
                    >
                      <el-form-item class="file-card">
                        <el-upload
                          class="upload-demo"
                          accept=".pdf"
                          :action="url + '/file'"
                          :on-success="uploadSuccessFile"
                          :before-upload="uploadBeforeFDFFile"
                          :on-remove="handleRemove"
                          :before-remove="beforeRemove"
                          :on-preview="handlePreview"
                          :headers="importHeaders"
                          :file-list="individualIncomeTax"
                          show-file-list
                        >
                          <div class="file-name">
                            <span style="color: red">* </span>个人所得税
                          </div>
                          <el-button
                            size="small"
                            round
                            class="file-button"
                            @click.native="setLabelMemo('个人所得税')"
                            >点击上传
                          </el-button>
                        </el-upload>
                      </el-form-item>
                    </el-form>
                  </v-card-actions>
                </v-col>
              </v-row>
            </el-form>
          </v-card-text>
        </v-card>

        <div class="mt-4"></div>

        <!-- 学历 -->
        <v-card flat>
          <div class="authentication-information">学历信息</div>
          <v-divider class="ml-4 mr-4"></v-divider>
          <v-card-text
            class="text-content"
            v-for="(item, index) in personalInformation.educations"
            :key="item.id"
          >
            <el-form
              :model="item"
              ref="personalInformation"
              label-width="auto"
              style="width: 100%"
            >
              <div v-if="item.sort === 0">
                <v-row>
                  <v-col cols="3">
                    <span>最高学历:</span>
                    <select class="select-style" v-model="item.academic">
                      <option
                        v-for="items in education"
                        :key="items.label"
                        v-html="items.label"
                      ></option>
                    </select>
                  </v-col>
                  <v-col cols="3">
                    <span>是否国外学历:</span>
                    <el-radio-group v-model="item.isStudyAbroad" class="ml-4">
                      <el-radio :label="true">是</el-radio>
                      <el-radio :label="false">否</el-radio>
                    </el-radio-group>
                  </v-col>
                  <v-col cols="3">
                    <span>毕业学校:</span>
                    <input class="input-style" v-model="item.school" />
                  </v-col>
                  <v-col cols="3">
                    <span>专业:</span>
                    <input class="input-style" v-model="item.major" />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="3">
                    <span>是否全日制:</span>
                    <el-radio-group v-model="item.type" class="ml-4">
                      <el-radio label="全日制">是</el-radio>
                      <el-radio label="非全日制">否</el-radio>
                    </el-radio-group>
                  </v-col>
                  <v-col cols="3">
                    <span>毕业时间:</span>
                    <el-date-picker
                      class="ml-2"
                      style="width: 160px"
                      size="small"
                      v-model="item.graduateTime"
                      type="date"
                      placeholder="开始年月"
                      value-format="yyyy-MM-dd"
                    >
                    </el-date-picker>
                  </v-col>
                  <v-col cols="3">
                    <span>学制(年):</span>
                    <input class="input-style" v-model="item.schoolSystem" />
                  </v-col>
                  <v-col cols="3">
                    <span>毕业证书编号:</span>
                    <input class="input-style" v-model="item.diplomaCode" />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="4">
                    <v-card-actions class="file-card-height">
                      <el-form
                        :model="personalInformation"
                        ref="enterpriseInformation"
                        label-width="auto"
                        style="width: 100%;"
                      >
                        <el-form-item class="file-card">
                          <el-upload
                            class="upload-demo"
                            accept=".png"
                            :action="url + '/file'"
                            :on-success="uploadSuccessFile"
                            :before-upload="uploadBeforeFile"
                            :on-remove="handleRemove"
                            :before-remove="beforeRemove"
                            :on-preview="handlePreview"
                            :headers="importHeaders"
                            :file-list="diplomaFile"
                            show-file-list
                          >
                            <div class="file-name">
                              <span style="color: red">* </span>最高学历证书
                            </div>
                            <el-button
                              size="small"
                              round
                              class="file-button"
                              @click.native="setLabelMemo('最高学历证书')"
                              >点击上传
                            </el-button>
                          </el-upload>
                        </el-form-item>
                      </el-form>
                    </v-card-actions>
                  </v-col>
                  <v-col cols="4">
                    <v-card-actions class="file-card-height">
                      <el-form
                        :model="personalInformation"
                        ref="enterpriseInformation"
                        label-width="auto"
                        style="width: 100%;"
                      >
                        <el-form-item class="file-card">
                          <el-upload
                            class="upload-demo"
                            accept=".png"
                            :action="url + '/file'"
                            :on-success="uploadSuccessFile"
                            :before-upload="uploadBeforeFile"
                            :on-remove="handleRemove"
                            :before-remove="beforeRemove"
                            :on-preview="handlePreview"
                            :headers="importHeaders"
                            :file-list="academicRecordFormFile"
                            show-file-list
                          >
                            <div class="file-name">
                              <span style="color: red">* </span
                              >最高学历电子信息备案表
                            </div>
                            <el-button
                              size="small"
                              round
                              class="file-button"
                              @click.native="
                                setLabelMemo('最高学历电子信息备案表')
                              "
                              >点击上传
                            </el-button>
                          </el-upload>
                        </el-form-item>
                      </el-form>
                    </v-card-actions>
                  </v-col>
                  <v-col cols="4">
                    <v-card-actions class="file-card-height">
                      <el-form
                        :model="personalInformation"
                        ref="enterpriseInformation"
                        label-width="auto"
                        style="width: 100%;"
                      >
                        <el-form-item class="file-card">
                          <el-upload
                            class="upload-demo"
                            accept=".png"
                            :action="url + '/file'"
                            :on-success="uploadSuccessFile"
                            :before-upload="uploadBeforeFile"
                            :on-remove="handleRemove"
                            :before-remove="beforeRemove"
                            :on-preview="handlePreview"
                            :headers="importHeaders"
                            :file-list="academicDegreeFile"
                            show-file-list
                          >
                            <div class="file-name">最高学位证书</div>
                            <el-button
                              size="small"
                              round
                              class="file-button"
                              @click.native="setLabelMemo('最高学位证书')"
                              >点击上传
                            </el-button>
                          </el-upload>
                        </el-form-item>
                      </el-form>
                    </v-card-actions>
                  </v-col>
                </v-row>
              </div>
              <div v-else>
                <v-row>
                  <v-col cols="3">
                    <span>学历:</span>
                    <select class="select-style" v-model="item.academic">
                      <option
                        v-for="items in education"
                        :key="items.label"
                        v-html="items.label"
                      ></option>
                    </select>
                  </v-col>
                  <v-col cols="3">
                    <span>是否国外学历:</span>
                    <el-radio-group v-model="item.isStudyAbroad" class="ml-4">
                      <el-radio :label="true">是</el-radio>
                      <el-radio :label="false">否</el-radio>
                    </el-radio-group>
                  </v-col>
                  <v-col cols="3">
                    <span>毕业学校:</span>
                    <input class="input-style" v-model="item.school" />
                  </v-col>
                  <v-col cols="3">
                    <span>专业:</span>
                    <input class="input-style" v-model="item.major" />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="3">
                    <span>是否全日制:</span>
                    <el-radio-group v-model="item.type" class="ml-4">
                      <el-radio label="全日制">是</el-radio>
                      <el-radio label="非全日制">否</el-radio>
                    </el-radio-group>
                  </v-col>
                  <v-col cols="3">
                    <span>毕业时间:</span>
                    <el-date-picker
                      class="ml-2"
                      style="width: 160px"
                      size="small"
                      v-model="item.graduateTime"
                      type="date"
                      placeholder="开始年月"
                      value-format="yyyy-MM-dd"
                    >
                    </el-date-picker>
                  </v-col>
                  <v-col cols="3">
                    <span>学制(年):</span>
                    <input class="input-style" v-model="item.schoolSystem" />
                  </v-col>
                  <v-col cols="3">
                    <span>毕业证书编号:</span>
                    <input class="input-style" v-model="item.diplomaCode" />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="3">
                    <v-card-actions class="file-card-height">
                      <el-form
                        :model="personalInformation"
                        ref="enterpriseInformation"
                        label-width="auto"
                        style="width: 100%;"
                      >
                        <el-form-item class="file-card">
                          <el-upload
                            class="upload-demo"
                            accept=".png"
                            :action="url + '/file'"
                            :on-success="uploadSuccessFile"
                            :before-upload="uploadBeforeFile"
                            :on-remove="handleRemove"
                            :before-remove="beforeRemove"
                            :on-preview="handlePreview"
                            :headers="importHeaders"
                            :file-list="item.diplomaFile"
                            show-file-list
                          >
                            <div class="file-name">
                              <span style="color: red">* </span>学历证书
                            </div>
                            <el-button
                              size="small"
                              round
                              class="file-button"
                              @click.native="setLabelMemo('学历证书', item.id)"
                              >点击上传
                            </el-button>
                          </el-upload>
                        </el-form-item>
                      </el-form>
                    </v-card-actions>
                  </v-col>
                  <v-col cols="4">
                    <v-card-actions class="file-card-height">
                      <el-form
                        :model="personalInformation"
                        ref="enterpriseInformation"
                        label-width="auto"
                        style="width: 100%;"
                      >
                        <el-form-item class="file-card">
                          <el-upload
                            class="upload-demo"
                            accept=".png"
                            :action="url + '/file'"
                            :on-success="uploadSuccessFile"
                            :before-upload="uploadBeforeFile"
                            :on-remove="handleRemove"
                            :before-remove="beforeRemove"
                            :on-preview="handlePreview"
                            :headers="importHeaders"
                            :file-list="item.academicRecordFormFile"
                            show-file-list
                          >
                            <div class="file-name">
                              <span style="color: red">* </span
                              >学历电子信息备案表
                            </div>
                            <el-button
                              size="small"
                              round
                              class="file-button"
                              @click.native="
                                setLabelMemo('学历电子信息备案表', item.id)
                              "
                            >
                              点击上传
                            </el-button>
                          </el-upload>
                        </el-form-item>
                      </el-form>
                    </v-card-actions>
                  </v-col>
                  <v-col cols="3">
                    <v-card-actions class="file-card-height">
                      <el-form
                        :model="personalInformation"
                        ref="enterpriseInformation"
                        label-width="auto"
                        style="width: 100%;"
                      >
                        <el-form-item class="file-card">
                          <el-upload
                            class="upload-demo"
                            accept=".png"
                            :action="url + '/file'"
                            :on-success="uploadSuccessFile"
                            :before-upload="uploadBeforeFile"
                            :on-remove="handleRemove"
                            :before-remove="beforeRemove"
                            :on-preview="handlePreview"
                            :headers="importHeaders"
                            :file-list="item.academicDegreeFile"
                            show-file-list
                          >
                            <div class="file-name">学位证书</div>
                            <el-button
                              size="small"
                              round
                              class="file-button"
                              @click.native="setLabelMemo('学位证书', item.id)"
                              >点击上传
                            </el-button>
                          </el-upload>
                        </el-form-item>
                      </el-form>
                    </v-card-actions>
                  </v-col>

                  <v-col cols="1">
                    <v-btn
                      color="error"
                      class="mt-4"
                      small
                      @click="onDeleteEducationInformation(item, index)"
                    >
                      <v-icon small>delete</v-icon>
                      删除学历
                    </v-btn>
                  </v-col>
                </v-row>
              </div>
              <v-divider></v-divider>
            </el-form>
          </v-card-text>

          <v-card-text>
            <v-btn
              text
              @click="onAddEducationInformation"
              :disabled="overstepEducation"
            >
              <v-icon small class="mr-1" color="#1090e7">add</v-icon>
              添加学历
            </v-btn>
          </v-card-text>
        </v-card>

        <div class="mt-4"></div>

        <!-- 个人简历 -->
        <v-card flat>
          <v-card-title class="authentication-resume">个人简历</v-card-title>
          <v-card-text class="text-content">
            <v-row style="width: 100%;text-align: left">
              <v-col cols="12">
                <textarea
                  class="textarea-style"
                  maxlength="500"
                  :rows="5"
                  v-model="personalInformation.resume"
                  placeholder="个人简历字，数不得超过500字"
                />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>

        <div class="mt-4"></div>

        <!-- 人才类型 -->
        <v-card flat>
          <v-row>
            <v-col cols="8">
              <v-card-title>
                <el-form
                  :model="sixEducation"
                  ref="personalInformation"
                  label-width="auto"
                  style="width: 100%"
                >
                  <v-row class="text-content">
                    <v-col cols="8">
                      <span>人才类型:</span>
                      <select
                        class="select-style"
                        v-model="personalInformation.type"
                      >
                        <option
                          v-for="item in talentType"
                          :key="item.name"
                          v-html="item.name"
                        ></option>
                      </select>
                    </v-col>
                  </v-row>
                </el-form>
              </v-card-title>

              <v-card-text class="talents-type">
                备注信息：
                <div class="remarks">
                  <el-input
                    autosize
                    v-model="clobs.content"
                    maxlength="200"
                    type="textarea"
                    :row="2"
                    placeholder="字数最大200"
                    onKeyDown="if (this.value.length>=200){this.value=this.value.substring(0,200)}"
                  />
                </div>
              </v-card-text>
            </v-col>
            <v-col cols="3" class="mt-16">
              <v-card-actions class="file-card-height">
                <el-form
                  :model="personalInformation"
                  ref="enterpriseInformation"
                  label-width="auto"
                  style="width: 100%;"
                >
                  <el-form-item class="file-card">
                    <el-upload
                      class="upload-demo"
                      accept=".png"
                      :action="url + '/file'"
                      :on-success="uploadSuccessFile"
                      :before-upload="uploadBeforeFile"
                      :on-remove="handleRemove"
                      :before-remove="beforeRemove"
                      :on-preview="handlePreview"
                      :headers="importHeaders"
                      :file-list="personnelTypeFile"
                      show-file-list
                    >
                      <div class="file-name">
                        <span style="color: red">* </span>人才类型
                      </div>
                      <el-button
                        size="small"
                        round
                        class="file-button"
                        @click.native="setLabelMemo('人才类型附件')"
                        >点击上传
                      </el-button>
                    </el-upload>
                  </el-form-item>
                </el-form>
              </v-card-actions>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <v-col cols="3">
        <v-card flat>
          <v-row>
            <v-col cols="10" offset="1">
              <v-card-actions class="file-card-height">
                <el-form
                  :model="personalInformation"
                  ref="enterpriseInformation"
                  label-width="auto"
                  style="width: 100%;"
                >
                  <el-form-item class="file-card">
                    <el-upload
                      class="upload-demo"
                      accept=".png"
                      :action="url + '/file'"
                      :on-success="uploadSuccessFile"
                      :before-upload="uploadBeforeFile"
                      :on-remove="handleRemove"
                      :before-remove="beforeRemove"
                      :on-preview="handlePreview"
                      :headers="importHeaders"
                      :file-list="idPhotos"
                      show-file-list
                    >
                      <div class="file-name">
                        <span style="color: red">* </span
                        >{{ personalInformation.identityType }}
                      </div>
                      <el-button
                        size="small"
                        round
                        class="file-button"
                        @click.native="setLabelMemo('身份证')"
                        >点击上传
                      </el-button>
                    </el-upload>
                  </el-form-item>
                </el-form>
              </v-card-actions>
            </v-col>
            <v-col cols="10" offset="1">
              <v-card-actions class="file-card-height">
                <el-form
                  :model="personalInformation"
                  ref="enterpriseInformation"
                  label-width="auto"
                  style="width: 100%;"
                >
                  <el-form-item class="file-card">
                    <el-upload
                      class="upload-demo"
                      accept=".png"
                      :action="url + '/file'"
                      :on-success="uploadSuccessFile"
                      :before-upload="uploadBeforeFile"
                      :on-remove="handleRemove"
                      :before-remove="beforeRemove"
                      :on-preview="handlePreview"
                      :headers="importHeaders"
                      :file-list="wordFile"
                      show-file-list
                    >
                      <div class="file-name">免冠工作照</div>
                      <el-button
                        size="small"
                        round
                        class="file-button"
                        @click.native="setLabelMemo('免冠工作照')"
                        >点击上传
                      </el-button>
                    </el-upload>
                  </el-form-item>
                </el-form>
              </v-card-actions>
            </v-col>
          </v-row>
        </v-card>

        <div class="mt-4"></div>

        <v-card flat>
          <v-card-text class="text-content">
            <span>缴纳社保时间: </span>
            <v-menu
              offset-y
              v-model="displayMenuSocialSecurityDate"
              :close-on-content-click="false"
              transition="scale-transition"
              min-width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-btn small depressed class="ml-2" v-on="on">
                  <v-icon small>event</v-icon>
                  {{ socialSecurityDate }}
                </v-btn>
              </template>
              <v-date-picker
                first-day-of-week="1"
                color="green lighten-1"
                v-model="personalInformation.socialSecurityPaymentTime"
                :show-current="false"
              >
                <v-spacer />
                <v-btn
                  text
                  small
                  @click="onIsSaveSocialSecurityDateCondition(0)"
                  >取消</v-btn
                >
                <v-btn
                  text
                  small
                  @click="onIsSaveSocialSecurityDateCondition(1)"
                  >确定</v-btn
                >
              </v-date-picker>
            </v-menu>
          </v-card-text>
          <v-divider class="ml-4 mr-4"></v-divider>
          <v-card-text class="text-content">
            <span>是否在赣州经开区缴纳社保: </span>
            <el-radio-group
              v-model="personalInformation.hasSocialSecurity"
              class="ml-4"
            >
              <el-radio :label="true">是</el-radio>
              <el-radio :label="false">否</el-radio>
            </el-radio-group>
          </v-card-text>
          <v-divider class="ml-4 mr-4"></v-divider>
          <v-card-text class="text-content">
            <span>是否买房: </span>
            <el-radio-group v-model="personalInformation.buyHouse" class="ml-4">
              <el-radio :label="true">是</el-radio>
              <el-radio :label="false">否</el-radio>
            </el-radio-group>
          </v-card-text>
          <v-divider class="ml-4 mr-4"></v-divider>
          <v-card-text class="text-content">
            <span>是否在中心城区有房:</span>
            <el-radio-group v-model="personalInformation.hasHouse" class="ml-4">
              <el-radio :label="true">是</el-radio>
              <el-radio :label="false">否</el-radio>
            </el-radio-group>
            <select
              class="select-style"
              v-model="personalInformation.block"
              v-if="personalInformation.hasHouse"
            >
              <option
                v-for="item in isbuyHouse"
                :key="item.label"
                v-html="item.label"
              >
              </option>
            </select>
          </v-card-text>
          <v-card-text class="card-height">
            <v-row>
              <v-col cols="10" offset="1">
                <v-card-actions class="file-card-height">
                  <el-form
                    :model="personalInformation"
                    ref="enterpriseInformation"
                    label-width="auto"
                    style="width: 100%;"
                  >
                    <el-form-item class="file-card">
                      <el-upload
                        class="upload-demo"
                        accept=".pdf"
                        :action="url + '/file'"
                        :on-success="uploadSuccessFile"
                        :before-upload="uploadBeforeFDFFile"
                        :on-remove="handleRemove"
                        :before-remove="beforeRemove"
                        :on-preview="handlePreview"
                        :headers="importHeaders"
                        :file-list="socialSecurityFile"
                        show-file-list
                      >
                        <div class="file-name">
                          <span style="color: red">* </span>社保附件
                        </div>
                        <el-button
                          size="small"
                          round
                          class="file-button"
                          @click.native="setLabelMemo('社保附件')"
                          >点击上传
                        </el-button>
                      </el-upload>
                    </el-form-item>
                  </el-form>
                </v-card-actions>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>

        <div class="mt-4"></div>

        <v-card flat>
          <div
            v-for="(item, index) in personalInformation.honors"
            :key="item.id"
          >
            <v-card-text class="text-content">
              <span>所获荣誉名称:</span>
              <input class="input-style" v-model="item.honorName" />
            </v-card-text>
            <v-card-text class="text-content">
              <span>实施部门:</span>
              <input
                class="input-style"
                v-model="item.implementationDepartment"
              />
            </v-card-text>
            <v-card-text class="text-content">
              <span>获奖年月:</span>
              <el-date-picker
                class="ml-2"
                style="width: 160px"
                size="small"
                v-model="item.awardYear"
                type="date"
                placeholder="开始年月"
                value-format="yyyy-MM-dd"
              >
              </el-date-picker>
            </v-card-text>
            <v-card-text class="text-content">
              <span>所获奖励级别:</span>
              <select class="select-style" v-model="item.rewardLevel">
                <option
                  v-for="honorItem in awardLevel"
                  :key="honorItem.value"
                  v-html="honorItem.value"
                ></option>
              </select>
            </v-card-text>
            <v-row>
              <v-col cols="10" offset="1">
                <v-card-actions class="file-card-height">
                  <el-form
                    :model="personalInformation"
                    ref="enterpriseInformation"
                    label-width="auto"
                    style="width: 100%;"
                  >
                    <el-form-item class="file-card">
                      <el-upload
                        class="upload-demo"
                        accept=".png"
                        :action="url + '/file'"
                        :on-success="uploadSuccessFile"
                        :before-upload="uploadBeforeFile"
                        :on-remove="handleRemove"
                        :before-remove="beforeRemove"
                        :on-preview="handlePreview"
                        :headers="importHeaders"
                        :file-list="item.files"
                        show-file-list
                      >
                        <div class="file-name">
                          <span style="color: red">* </span>所获荣誉
                        </div>
                        <el-button
                          size="small"
                          round
                          class="file-button"
                          @click.native="setLabelMemo('所获荣誉', item.id)"
                          >点击上传
                        </el-button>
                      </el-upload>
                    </el-form-item>
                  </el-form>
                </v-card-actions>
              </v-col>

              <v-col cols="8" offset="2">
                <v-btn
                  small
                  color="error"
                  @click="onDeleteCertificateHonor(item, index)"
                >
                  <v-icon small class="mr-1">delete</v-icon>
                  删除荣誉信息
                </v-btn>
              </v-col>
            </v-row>

            <v-divider class="ml-4 mr-4"></v-divider>
          </div>

          <v-card-text>
            <v-btn text @click="onAddCertificateHonor">
              <v-icon small class="mr-1" color="#1090e7">add</v-icon>
              添加荣誉信息
            </v-btn>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row v-else>
      <v-col cols="9">
        <v-card flat>
          <v-card-text class="text-content">
            <el-form
              :model="personalInformation"
              ref="personalInformation"
              label-width="auto"
              style="width: 100%;"
            >
              <v-row>
                <v-col cols="3">
                  <span>姓名:</span>
                  {{ personalInformation.name }}
                </v-col>
                <v-col cols="3">
                  <span>出生年月:</span>
                  {{ personalInformation.birthday }}
                </v-col>
                <v-col cols="3">
                  <span>性别:</span>
                  <span
                    class="content"
                    v-html="personalInformation.sex === 1 ? '女' : '男'"
                  ></span>
                </v-col>
                <v-col cols="3">
                  <span>国籍:</span>
                  {{ personalInformation.citizenship }}
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="3"
                  v-if="personalInformation.citizenship === '中国'"
                >
                  <span>民族:</span>
                  {{ personalInformation.nation }}
                </v-col>
                <v-col cols="3">
                  <span>联系电话:</span>
                  {{ personalInformation.phone }}
                </v-col>
                <v-col cols="3">
                  <span>邮箱:</span>
                  {{ personalInformation.email }}
                </v-col>
                <v-col
                  cols="3"
                  v-if="personalInformation.citizenship === '中国'"
                >
                  <span>籍贯:</span>
                  {{ personalInformation.birthplace }}
                </v-col>
                <v-col cols="3">
                  <span>证件类型:</span>
                  {{ personalInformation.identityType }}
                </v-col>
                <v-col cols="3">
                  <span>证件编号:</span>
                  {{ personalInformation.identityCode }}
                </v-col>
                <v-col cols="3">
                  <span>证件开始有效日期:</span>
                  <span
                    class="content"
                    v-html="
                      personalInformation.startEffectiveDate
                        ? personalInformation.startEffectiveDate.substring(
                            0,
                            10
                          )
                        : personalInformation.startEffectiveDate
                    "
                  ></span>
                </v-col>
                <v-col cols="3">
                  <span>证件结束有效日期:</span>
                  <span
                    class="content"
                    v-html="
                      personalInformation.endEffectiveDate
                        ? personalInformation.endEffectiveDate.substring(0, 10)
                        : personalInformation.endEffectiveDate
                    "
                  ></span>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <span>户籍住址:</span>
                  {{ personalInformation.addressed }}
                </v-col>
              </v-row>
            </el-form>
          </v-card-text>
        </v-card>

        <div class="mt-4"></div>

        <!-- 工作履历 -->
        <v-card flat>
          <v-card-text class="text-content">
            <el-form
              :model="personalInformation"
              ref="personalInformation"
              label-width="auto"
              style="width: 100%;"
            >
              <v-row>
                <v-col cols="4">
                  <span>是否省外引进:</span>
                  {{ personalInformation.talentOccupation.rankLevel }}
                </v-col>
                <v-col cols="4">
                  <span>从事专业领域:</span>
                  {{ personalInformation.talentOccupation.skillCategory }}
                </v-col>
                <v-col cols="4">
                  <span>引进时间：</span>
                  <span
                    class="content"
                    v-html="
                      personalInformation.talentOccupation.introductionTime
                        ? personalInformation.talentOccupation.introductionTime.substring(
                            0,
                            10
                          )
                        : personalInformation.talentOccupation.introductionTime
                    "
                  ></span>
                </v-col>
              </v-row>
            </el-form>
          </v-card-text>
        </v-card>

        <div class="mt-4"></div>

        <!-- 目前就职 -->
        <v-card flat>
          <v-card-text class="text-content">
            <el-form
              :model="personalInformation"
              ref="personalInformation"
              label-width="auto"
              style="width: 100%;"
            >
              <v-row>
                <v-col cols="3">
                  <span>目前供职公司:</span>
                  {{ personalInformation.enterpriseName }}
                </v-col>
                <v-col cols="3">
                  <span>所在单位职务:</span>
                  {{ personalInformation.talentOccupation.position }}
                </v-col>
                <v-col cols="3">
                  <span>入职时间:</span>
                  {{
                    personalInformation.talentOccupation.hireDate | formatDate
                  }}
                </v-col>
                <v-col cols="3">
                  <span>职称类别:</span>
                  {{ personalInformation.talentOccupation.rankCategory }}
                </v-col>
              </v-row>

              <v-row class="card-height">
                <div
                  class="file"
                  v-for="item in laborContractFile"
                  :key="item.id"
                >
                  <v-btn
                    depressed
                    class="see-file"
                    color="primary"
                    @click="getFile(item.path)"
                  >
                    {{ item.memo }}
                  </v-btn>
                  <v-btn
                    outlined
                    rounded
                    fab
                    x-small
                    @click="getDownloadFile(item)"
                    class="ml-1"
                    color="primary"
                  >
                    下载
                  </v-btn>
                </div>
                <div
                  class="file"
                  v-for="item in individualIncomeTax"
                  :key="item.id"
                >
                  <v-btn
                    depressed
                    class="see-file"
                    color="primary"
                    @click="getFile(item.path)"
                  >
                    {{ item.memo }}
                  </v-btn>
                  <v-btn
                    outlined
                    rounded
                    fab
                    x-small
                    @click="getDownloadFile(item)"
                    class="ml-1"
                    color="primary"
                  >
                    下载
                  </v-btn>
                </div>
              </v-row>
            </el-form>
          </v-card-text>
        </v-card>

        <div class="mt-4"></div>

        <!-- 工作履历 -->
        <v-card flat>
          <v-card-title class="authentication-information"
            >工作履历</v-card-title
          >
          <v-divider class="ml-4 mr-4"></v-divider>
          <v-card-text class="text-content">
            <el-form
              :model="personalInformation"
              ref="personalInformation"
              label-width="auto"
              style="width: 100%;"
            >
              <v-row v-for="item in personalInformation.resumes" :key="item.id">
                <v-col cols="4">
                  <span>工作单位名称:</span>
                  {{ item.companyName }}
                </v-col>
                <v-col cols="4">
                  <span>职位:</span>
                  {{ item.jobTitle }}
                </v-col>
                <v-col cols="4">
                  <span>起止年月:</span>
                  {{ item.startDate | formatDate }} 至
                  {{ item.endDate | formatDate }}
                </v-col>
              </v-row>
            </el-form>
          </v-card-text>
        </v-card>

        <div class="mt-4"></div>

        <!-- 学历 -->
        <v-card flat>
          <el-menu
            :default-active="activeIndex"
            class="el-menu-demo"
            mode="horizontal"
            @select="handleSelect"
          >
            <el-menu-item index="1" text-color="#0c94ea">最高学历</el-menu-item>
            <el-menu-item index="2" v-if="twoEducation.sort"
              >第二学历</el-menu-item
            >
            <el-menu-item index="3" v-if="threeEducation.sort"
              >第三学历</el-menu-item
            >
            <el-menu-item index="4" v-if="fourEducation.sort"
              >第四学历</el-menu-item
            >
            <el-menu-item index="5" v-if="fiveEducation.sort"
              >第五学历</el-menu-item
            >
            <el-menu-item index="6" v-if="sixEducation.sort"
              >第六学历</el-menu-item
            >
          </el-menu>

          <v-card-text class="text-content" v-if="activeEducation === '1'">
            <el-form
              :model="oneEducation"
              ref="personalInformation"
              label-width="auto"
              style="width: 100%"
            >
              <v-row>
                <v-col cols="3">
                  <span>最高学历:</span>
                  {{ oneEducation.academic }}
                </v-col>
                <v-col cols="3">
                  <span>是否国外学历:</span>
                  <span
                    class="content"
                    v-html="oneEducation.isStudyAbroad === true ? '是' : '否'"
                  ></span>
                </v-col>
                <v-col cols="3">
                  <span>毕业学校:</span>
                  {{ oneEducation.school }}
                </v-col>
                <v-col cols="3">
                  <span>专业:</span>
                  {{ oneEducation.major }}
                </v-col>
              </v-row>
              <v-divider></v-divider>
              <v-row>
                <v-col cols="3" v-if="!oneEducation.isStudyAbroad">
                  <span>是否全日制:</span>
                  <span
                    class="content"
                    v-html="oneEducation.type === '全日制' ? '是' : '否'"
                  ></span>
                </v-col>
                <v-col cols="3">
                  <span>毕业时间:</span>
                  {{ oneEducation.graduateTime | formatDate }}
                </v-col>
                <v-col cols="3">
                  <span>学制(年):</span>
                  {{ oneEducation.schoolSystem }}
                </v-col>
                <v-col cols="3" v-if="!oneEducation.isStudyAbroad">
                  <span>毕业证书编号:</span>
                  {{ oneEducation.diplomaCode }}
                </v-col>
              </v-row>
              <v-divider></v-divider>
              <v-row class="card-height mt-6">
                <div
                  class="file"
                  v-for="item in oneEducationFile"
                  :key="item.id"
                >
                  <v-btn
                    depressed
                    class="see-file"
                    color="primary"
                    @click="getFile(item.path)"
                  >
                    {{ item.memo }}
                  </v-btn>
                  <v-btn
                    outlined
                    rounded
                    fab
                    x-small
                    class="ml-1"
                    color="primary"
                    @click="getDownloadFile(item)"
                  >
                    下载
                  </v-btn>
                </div>
              </v-row>
            </el-form>
          </v-card-text>

          <v-card-text class="text-content" v-if="activeEducation === '2'">
            <el-form
              :model="twoEducation"
              ref="personalInformation"
              label-width="auto"
              style="width: 100%"
            >
              <v-row>
                <v-col cols="3">
                  <span>第二学历:</span>
                  {{ twoEducation.academic }}
                </v-col>
                <v-col cols="3">
                  <span>是否国外学历:</span>
                  <span
                    class="content"
                    v-html="twoEducation.isStudyAbroad === true ? '是' : '否'"
                  ></span>
                </v-col>
                <v-col cols="3">
                  <span>毕业学校:</span>
                  {{ twoEducation.school }}
                </v-col>
                <v-col cols="3">
                  <span>专业:</span>
                  {{ twoEducation.major }}
                </v-col>
              </v-row>
              <v-divider></v-divider>
              <v-row>
                <v-col cols="3" v-if="!twoEducation.isStudyAbroad">
                  <span>是否全日制:</span>
                  <span
                    class="content"
                    v-html="twoEducation.type === '全日制' ? '是' : '否'"
                  ></span>
                </v-col>
                <v-col cols="3">
                  <span>毕业时间:</span>
                  {{ twoEducation.graduateTime | formatDate }}
                </v-col>
                <v-col cols="3">
                  <span>学制(年):</span>
                  {{ twoEducation.schoolSystem }}
                </v-col>
                <v-col cols="3" v-if="!twoEducation.isStudyAbroad">
                  <span>毕业证书编号:</span>
                  {{ twoEducation.diplomaCode }}
                </v-col>
              </v-row>
              <v-divider></v-divider>
              <v-row class="card-height mt-6">
                <div
                  class="file"
                  v-for="item in twoEducation.files"
                  :key="item.id"
                >
                  <v-btn
                    depressed
                    class="see-file"
                    color="primary"
                    @click="getFile(item.path)"
                  >
                    {{ item.memo }}
                  </v-btn>
                  <v-btn
                    outlined
                    rounded
                    fab
                    x-small
                    class="ml-1"
                    color="primary"
                    @click="getDownloadFile(item)"
                  >
                    下载
                  </v-btn>
                </div>
              </v-row>
            </el-form>
          </v-card-text>

          <v-card-text class="text-content" v-if="activeEducation === '3'">
            <el-form
              :model="threeEducation"
              ref="personalInformation"
              label-width="auto"
              style="width: 100%"
            >
              <v-row>
                <v-col cols="3">
                  <span>第三学历:</span>
                  {{ threeEducation.academic }}
                </v-col>
                <v-col cols="3">
                  <span>是否国外学历:</span>
                  <span
                    class="content"
                    v-html="threeEducation.isStudyAbroad === true ? '是' : '否'"
                  ></span>
                </v-col>
                <v-col cols="3">
                  <span>毕业学校:</span>
                  {{ threeEducation.school }}
                </v-col>
                <v-col cols="3">
                  <span>专业:</span>
                  {{ threeEducation.major }}
                </v-col>
              </v-row>
              <v-divider></v-divider>
              <v-row>
                <v-col cols="3" v-if="!threeEducation.isStudyAbroad">
                  <span>是否全日制:</span>
                  <span
                    class="content"
                    v-html="threeEducation.type === '全日制' ? '是' : '否'"
                  ></span>
                </v-col>
                <v-col cols="3">
                  <span>毕业时间:</span>
                  {{ threeEducation.graduateTime | formatDate }}
                </v-col>
                <v-col cols="3">
                  <span>学制(年):</span>
                  {{ threeEducation.schoolSystem }}
                </v-col>
                <v-col cols="3" v-if="!threeEducation.isStudyAbroad">
                  <span>毕业证书编号:</span>
                  {{ threeEducation.diplomaCode }}
                </v-col>
              </v-row>
              <v-divider></v-divider>
              <v-row class="card-height mt-6">
                <div
                  class="file"
                  v-for="item in threeEducation.files"
                  :key="item.id"
                >
                  <v-btn
                    depressed
                    class="see-file"
                    color="primary"
                    @click="getFile(item.path)"
                  >
                    {{ item.memo }}
                  </v-btn>
                  <v-btn
                    outlined
                    rounded
                    fab
                    x-small
                    class="ml-1"
                    color="primary"
                    @click="getDownloadFile(item)"
                  >
                    下载
                  </v-btn>
                </div>
              </v-row>
            </el-form>
          </v-card-text>

          <v-card-text class="text-content" v-if="activeEducation === '4'">
            <el-form
              :model="fourEducation"
              ref="personalInformation"
              label-width="auto"
              style="width: 100%"
            >
              <v-row>
                <v-col cols="3">
                  <span>第四学历:</span>
                  {{ fourEducation.academic }}
                </v-col>
                <v-col cols="3">
                  <span>是否国外学历:</span>
                  <span
                    class="content"
                    v-html="fourEducation.isStudyAbroad === true ? '是' : '否'"
                  ></span>
                </v-col>
                <v-col cols="3">
                  <span>毕业学校:</span>
                  {{ fourEducation.school }}
                </v-col>
                <v-col cols="3">
                  <span>专业:</span>
                  {{ fourEducation.major }}
                </v-col>
              </v-row>
              <v-divider></v-divider>
              <v-row>
                <v-col cols="3" v-if="!fourEducation.isStudyAbroad">
                  <span>是否全日制:</span>
                  <span
                    class="content"
                    v-html="fourEducation.type === '全日制' ? '是' : '否'"
                  ></span>
                </v-col>
                <v-col cols="3">
                  <span>毕业时间:</span>
                  {{ fourEducation.graduateTime | formatDate }}
                </v-col>
                <v-col cols="3">
                  <span>学制(年):</span>
                  {{ fourEducation.schoolSystem }}
                </v-col>
                <v-col cols="3" v-if="!fourEducation.isStudyAbroad">
                  <span>毕业证书编号:</span>
                  {{ fourEducation.diplomaCode }}
                </v-col>
              </v-row>
              <v-divider></v-divider>
              <v-row class="card-height mt-6">
                <div
                  class="file"
                  v-for="item in fourEducation.files"
                  :key="item.id"
                >
                  <v-btn
                    depressed
                    class="see-file"
                    color="primary"
                    @click="getFile(item.path)"
                  >
                    {{ item.memo }}
                  </v-btn>
                  <v-btn
                    outlined
                    rounded
                    fab
                    x-small
                    class="ml-1"
                    color="primary"
                    @click="getDownloadFile(item)"
                  >
                    下载
                  </v-btn>
                </div>
              </v-row>
            </el-form>
          </v-card-text>

          <v-card-text class="text-content" v-if="activeEducation === '5'">
            <el-form
              :model="fiveEducation"
              ref="personalInformation"
              label-width="auto"
              style="width: 100%"
            >
              <v-row>
                <v-col cols="3">
                  <span>第五学历:</span>
                  {{ fiveEducation.academic }}
                </v-col>
                <v-col cols="3">
                  <span>是否国外学历:</span>
                  <span
                    class="content"
                    v-html="fiveEducation.isStudyAbroad === true ? '是' : '否'"
                  ></span>
                </v-col>
                <v-col cols="3">
                  <span>毕业学校:</span>
                  {{ fiveEducation.school }}
                </v-col>
                <v-col cols="3">
                  <span>专业:</span>
                  {{ fiveEducation.major }}
                </v-col>
              </v-row>
              <v-divider></v-divider>
              <v-row>
                <v-col cols="3" v-if="!fiveEducation.isStudyAbroad">
                  <span>是否全日制:</span>
                  <span
                    class="content"
                    v-html="fiveEducation.type === '全日制' ? '是' : '否'"
                  ></span>
                </v-col>
                <v-col cols="3">
                  <span>毕业时间:</span>
                  {{ fiveEducation.graduateTime | formatDate }}
                </v-col>
                <v-col cols="3">
                  <span>学制(年):</span>
                  {{ fiveEducation.schoolSystem }}
                </v-col>
                <v-col cols="3" v-if="!fiveEducation.isStudyAbroad">
                  <span>毕业证书编号:</span>
                  {{ fiveEducation.diplomaCode }}
                </v-col>
              </v-row>
              <v-divider></v-divider>
              <v-row class="card-height mt-6">
                <div
                  class="file"
                  v-for="item in fiveEducation.files"
                  :key="item.id"
                >
                  <v-btn
                    depressed
                    class="see-file"
                    color="primary"
                    @click="getFile(item.path)"
                  >
                    {{ item.memo }}
                  </v-btn>
                  <v-btn
                    outlined
                    rounded
                    fab
                    x-small
                    class="ml-1"
                    color="primary"
                    @click="getDownloadFile(item)"
                  >
                    下载
                  </v-btn>
                </div>
              </v-row>
            </el-form>
          </v-card-text>

          <v-card-text class="text-content" v-if="activeEducation === '6'">
            <el-form
              :model="sixEducation"
              ref="personalInformation"
              label-width="auto"
              style="width: 100%"
            >
              <v-row>
                <v-col cols="3">
                  <span>第六学历:</span>
                  {{ sixEducation.academic }}
                </v-col>
                <v-col cols="3">
                  <span>是否国外学历:</span>
                  <span
                    class="content"
                    v-html="sixEducation.isStudyAbroad === true ? '是' : '否'"
                  ></span>
                </v-col>
                <v-col cols="3">
                  <span>毕业学校:</span>
                  {{ sixEducation.school }}
                </v-col>
                <v-col cols="3">
                  <span>专业:</span>
                  {{ sixEducation.major }}
                </v-col>
              </v-row>
              <v-divider></v-divider>
              <v-row>
                <v-col cols="3" v-if="!sixEducation.isStudyAbroad">
                  <span>是否全日制:</span>
                  <span
                    class="content"
                    v-html="sixEducation.type === '全日制' ? '是' : '否'"
                  ></span>
                </v-col>
                <v-col cols="3">
                  <span>毕业时间:</span>
                  {{ sixEducation.graduateTime | formatDate }}
                </v-col>
                <v-col cols="3">
                  <span>学制(年):</span>
                  {{ sixEducation.schoolSystem }}
                </v-col>
                <v-col cols="3" v-if="!sixEducation.isStudyAbroad">
                  <span>毕业证书编号:</span>
                  {{ sixEducation.diplomaCode }}
                </v-col>
              </v-row>
              <v-divider></v-divider>
              <v-row class="card-height mt-6">
                <div
                  class="file"
                  v-for="item in sixEducation.files"
                  :key="item.id"
                >
                  <v-btn
                    depressed
                    class="see-file"
                    color="primary"
                    @click="getFile(item.path)"
                  >
                    {{ item.memo }}
                  </v-btn>
                  <v-btn
                    outlined
                    rounded
                    fab
                    x-small
                    class="ml-1"
                    color="primary"
                    @click="getDownloadFile(item)"
                  >
                    下载
                  </v-btn>
                </div>
              </v-row>
            </el-form>
          </v-card-text>
        </v-card>

        <div class="mt-4"></div>

        <!-- 个人简历 -->
        <v-card flat>
          <v-card-title class="authentication-resume">个人简历</v-card-title>
          <v-card-text class="text-content">
            <v-row style="width: 100%;text-align: left">
              <v-col cols="12">
                <div class="policy-resume">
                  {{ personalInformation.resume }}
                </div>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>

        <div class="mt-4"></div>

        <!-- 人才类型 -->
        <v-card flat>
          <v-card-title>
            <el-form
              :model="sixEducation"
              ref="personalInformation"
              label-width="auto"
              style="width: 100%"
            >
              <v-row>
                <v-col cols="4">
                  <el-form-item label="人才类型">
                    <el-select
                      v-model="personalInformation.type"
                      placeholder="请选择"
                      filterable
                    >
                      <el-option
                        v-for="item in talentType"
                        :key="item.id"
                        :label="item.name"
                        :value="item.name"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>
                </v-col>
              </v-row>
            </el-form>
          </v-card-title>

          <v-card-text class="talents-type">
            备注信息：
            <v-row>
              <v-col cols="8">
                <div class="remarks">
                  <el-input
                    autosize
                    v-model="clobs.content"
                    maxlength="200"
                    type="textarea"
                    :row="2"
                    placeholder="字数最大200"
                    onKeyDown="if (this.value.length>=200){this.value=this.value.substring(0,200)}"
                  />
                </div>
              </v-col>
              <v-col cols="4">
                <v-card-text class="card-height">
                  <div
                    class="file"
                    v-for="item in personnelTypeFile"
                    :key="item.id"
                  >
                    <v-btn
                      depressed
                      class="see-file"
                      color="primary"
                      @click="getFile(item.path)"
                    >
                      {{ item.memo }}
                    </v-btn>
                    <v-btn
                      outlined
                      rounded
                      fab
                      x-small
                      class="ml-1"
                      color="primary"
                      @click="getDownloadFile(item)"
                    >
                      下载
                    </v-btn>
                  </div>
                </v-card-text>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="3">
        <v-card flat>
          <v-row class="card-height pt-4">
            <div class="id-file" v-for="item in idPhotos" :key="item.id">
              <v-img
                :src="item.path"
                :aspect-ratio="1"
                class="id-photo"
                contain
                alt="加载失败"
                @click="getIdFile(item.path)"
              ></v-img>
              <v-btn
                outlined
                rounded
                fab
                x-small
                class="ml-1 down"
                color="primary"
                @click="getDownloadFile(item)"
              >
                下载
              </v-btn>
            </div>
            <div class="file mt-4" v-for="item in wordFile" :key="item.id">
              <v-btn
                depressed
                class="see-file"
                color="primary"
                @click="getFile(item.path)"
              >
                {{ item.memo }}预览
              </v-btn>
              <v-btn
                outlined
                fab
                x-small
                class="ml-1"
                color="primary"
                @click="getDownloadFile(item)"
              >
                下载
              </v-btn>
            </div>
          </v-row>
        </v-card>

        <div class="mt-4"></div>

        <v-card flat>
          <v-card-text class="text-content">
            <span>缴纳社保时间: </span>
            <span class="content">{{
              personalInformation.socialSecurityPaymentTime | formatDate
            }}</span>
          </v-card-text>
          <v-divider class="ml-4 mr-4"></v-divider>
          <v-card-text class="text-content">
            <span>是否在赣州经开区缴纳社保: </span>
            <span
              class="content"
              v-html="
                personalInformation.hasSocialSecurity === true ? '是' : '否'
              "
            ></span>
          </v-card-text>
          <v-divider class="ml-4 mr-4"></v-divider>
          <v-card-text class="text-content">
            <span>是否买房: </span>
            <span
              class="content"
              v-html="personalInformation.buyHouse === true ? '是' : '否'"
            ></span>
          </v-card-text>
          <v-divider class="ml-4 mr-4"></v-divider>
          <v-card-text
            class="text-content"
            v-show="personalInformation.buyHouse"
          >
            <v-icon color="#2a3746" class="mr-4">domain_disabled</v-icon>
            <span>是否在中心城区有房: </span>
            <span class="content" v-if="personalInformation.hasHouse">{{
              personalInformation.block
            }}</span>
            <span class="content" v-else>否</span>
          </v-card-text>
          <v-card-text class="card-height">
            <div class="file" v-for="item in socialSecurityFile" :key="item.id">
              <v-btn
                depressed
                class="see-file"
                color="primary"
                @click="getFile(item.path)"
              >
                {{ item.memo }}
              </v-btn>
              <v-btn
                outlined
                rounded
                fab
                x-small
                class="ml-1"
                color="primary"
                @click="getDownloadFile(item)"
              >
                下载
              </v-btn>
            </div>
          </v-card-text>
        </v-card>

        <div class="mt-4"></div>

        <v-card flat>
          <div class="honor">
            所获荣誉 ({{ honorNumber }}个)
            <v-menu offset-y>
              <template v-slot:activator="{ on }">
                <v-btn color="#0c94ea" fab text small v-on="on" class="fr mt-1">
                  <v-icon color="#FFFFFF">keyboard_arrow_down</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item v-for="item in items" :key="item.id">
                  <v-list-item-action
                    @click="selectCertificate(item)"
                    style="cursor: pointer"
                  >
                    {{ item.name }}
                  </v-list-item-action>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
          <div>
            <v-card-text class="text-content">
              <span>所获荣誉名称:</span>
              {{ honor.honorName }}
            </v-card-text>
            <v-divider class="ml-4 mr-4"></v-divider>
            <v-card-text class="text-content">
              <span>实施部门:</span>
              {{ honor.implementationDepartment }}
            </v-card-text>
            <v-divider class="ml-4 mr-4"></v-divider>
            <v-card-text class="text-content">
              <span>获奖年月:</span>
              <span
                class="content"
                v-html="
                  honor.awardYear
                    ? honor.awardYear.substring(0, 10)
                    : honor.awardYear
                "
              ></span>
            </v-card-text>
            <v-divider class="ml-4 mr-4"></v-divider>
            <v-card-text class="text-content">
              <span>所获奖励级别:</span>
              {{ honor.rewardLevel }}
            </v-card-text>
            <v-row class="card-height mt-2">
              <div
                class="file"
                v-for="fileItem in honor.files"
                :key="fileItem.id"
              >
                <v-btn
                  depressed
                  class="see-file"
                  color="primary"
                  @click="getFile(fileItem.path)"
                >
                  {{ fileItem.memo }}
                </v-btn>
                <v-btn
                  outlined
                  rounded
                  fab
                  x-small
                  class="ml-1"
                  color="primary"
                >
                  <v-icon @click="getDownloadFile(fileItem)">下载</v-icon>
                </v-btn>
              </div>
            </v-row>
          </div>
        </v-card>
      </v-col>
    </v-row>

    <div v-if="isEditPersonalInformation">
      <v-btn
        class="ps-6 mr-8"
        color="primary"
        @click="onSubmitPersonalInformation"
        >确定修改</v-btn
      >
      <v-btn color="error" @click="getCancelEditPersonalInformationDialog"
        >取消修改</v-btn
      >
    </div>

    <div class="button" v-else>
      <div>
        <el-row>
          <el-col>
            <el-row v-if="personalInformation.status === 11">
              <el-button type="primary" class="text" @click="review(21)"
                >申请通过</el-button
              >
              <el-button type="danger" class="text" @click="review(20)"
                >驳回申请</el-button
              >
              <el-button @click="keeps">保存备注</el-button>
            </el-row>
            <el-row v-else-if="personalInformation.status === 20">
              <el-button type="warning" class="text">已驳回</el-button>
              <el-button @click="keeps">保存备注</el-button>
            </el-row>
            <el-row v-else-if="personalInformation.status === 21">
              <el-button type="success" class="text">已同意</el-button>
              <el-button @click="keeps">保存备注</el-button>
            </el-row>
          </el-col>
        </el-row>
      </div>
    </div>

    <el-dialog
      title="政策经历"
      :visible.sync="policyExperience"
      :close-on-press-escape="true"
      :close-on-click-modal="true"
    >
      <el-button
        type="text"
        icon="el-icon-plus"
        @click="experienceVisible = true"
        >添加政策经历</el-button
      >
      <el-table :data="policyInformation">
        <el-table-column prop="ownerName" label="姓名" min-width="100">
          <template slot-scope="scope">{{ scope.row.ownerName }}</template>
        </el-table-column>
        <el-table-column prop="policyTitle" label="政策标题" min-width="240">
          <template slot-scope="scope">{{ scope.row.policyTitle }}</template>
        </el-table-column>
        <el-table-column prop="comment" label="备注" min-width="200">
          <template slot-scope="scope">{{ scope.row.comment }}</template>
        </el-table-column>
        <el-table-column prop="policyExpireTime" label="日期" min-width="120">
          <template slot-scope="scope">{{
            scope.row.policyExpireTime | formatDate
          }}</template>
        </el-table-column>
      </el-table>
    </el-dialog>

    <el-dialog
      title="添加政策经历"
      :visible.sync="experienceVisible"
      width="350px"
    >
      <el-form ref="form" label-width="80px">
        <el-form-item label="政策名称">
          <el-input v-model="form.name"></el-input>
        </el-form-item>
        <el-form-item label="政策类型">
          <el-select v-model="form.region" placeholder="请选择活动区域">
            <el-option label="区域一" value="shanghai"></el-option>
            <el-option label="区域二" value="beijing"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="申报日期">
          <el-date-picker
            v-model="form.date"
            type="date"
            placeholder="选择日期"
          >
          </el-date-picker>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="experienceVisible = false">取 消</el-button>
        <el-button type="primary" @click="experienceVisible = false"
          >确 定</el-button
        >
      </span>
    </el-dialog>

    <el-dialog
      title="工作经历"
      :visible.sync="workExperience"
      :close-on-click-modal="true"
    >
      <el-table :data="workExperienceData">
        <el-table-column prop="name" label="姓名" width="150"></el-table-column>
        <el-table-column
          prop="company"
          label="公司"
          width="200"
        ></el-table-column>
        <el-table-column prop="time" label="入职时间"></el-table-column>
      </el-table>
    </el-dialog>

    <el-dialog title="驳回理由" :visible.sync="dialogVisible" width="520px">
      <el-input
        type="textarea"
        :rows="5"
        v-model="contentNew"
        placeholder="请输入驳回理由"
        maxlength="50"
      ></el-input>
      <div style="font-size: 12px;float: left;overflow: hidden">
        字数不超过50字
      </div>
      <div style="margin-top: 40px"></div>
      <el-button type="primary" @click="requestReject" class="text"
        >确 定</el-button
      >
      <el-button @click="dialogVisible = false">取 消</el-button>
    </el-dialog>

    <el-dialog
      title="预览"
      :visible.sync="seePhoto"
      :width="oWidth"
      style="margin-top: -32px"
      :close-on-click-modal="true"
    >
      <v-img :src="addressId" alt="" v-if="addressId.length > 0" />
      <iframe
        :src="pdf"
        frameborder="0"
        style="width: 100%; height: 700px"
        v-if="pdf"
      ></iframe>
      <div class="hr-10"></div>
      <el-button type="primary" @click="seePhoto = false" class="text"
        >关闭</el-button
      >
    </el-dialog>
  </div>
</template>

<script>
import configDate from "../../../utils/config";
import address from "../../../utils/china_regions";

export default {
  components: {},
  data() {
    return {
      pdf: "",
      oWidth: "500px",
      personalInformation: {
        address: "", // 编辑时的回显地址
        addressed: "", // 办理时的回显地址
        addresses: [], // 选择时的地址
        addressId: [], // 地址id
        details: "", // 详细地址
        birthday: "", // 出生年月
        birthplace: "", // 籍贯
        block: "", // 住房所在区
        buyHouse: false, // 是否买房
        citizenship: "", // 国籍
        email: "", // 邮箱
        endEffectiveDate: "", // 证件有效结束日期
        enterpriseName: "", // 目前就职公司
        enterpriseId: "", // 目前就职公司id
        files: [], // 上传的文件信息
        fileIds: [], // 上传文件的id
        hasHouse: false, // 在城中心是否有房
        hasSocialSecurity: false, // 是否缴纳有社保
        honors: [], // 所获荣誉
        id: 0,
        identityCode: 0, // 证件号码
        identityType: 0, // 证件类型
        memo: "", // 驳回人才备注
        name: "",
        nation: "", //民族
        passportExpireDate: "", // 护照到期时间
        passportNumber: "", // 护照号码
        phone: "", // 手机号码
        resume: "", // 个人简历
        resumes: [], // 工作履历
        sex: 0, // 性别
        socialSecurityPaymentTime: "", // 社保缴纳时间
        startEffectiveDate: "", // 证件有效开始日期
        status: 0, // 状态
        talentEducations: [], // 教育信息
        talentOccupation: [], // 职业信息
        occupation: {}, // 目前工作
        type: "", // 人才类型
        clobs: [], // 人才备注
      },
      /*
            引进地方
            */
      introduce: configDate.introduce,
      /*
            从事专业领域
            */
      professionalField: configDate.professionalField,
      /*
            职称类别
            */
      optionsCertificate: configDate.optionsCertificate,
      /*
            所获荣誉级别
            */
      awardLevel: [
        { value: "国家级", number: 1 },
        { value: "省级", number: 2 },
        { value: "市级", number: 3 },
        { value: "其他 ", number: 4 },
      ],
      /*
            证件类型
            */
      documentType: [
        "身份证",
        "普通护照",
        "港澳居民来内地通行证",
        "台湾居民来内地通行证",
        "外国人永久居留证",
      ],
      /*
            选择已认证的单位
            */
      enterpriseName: [],
      activeIndex: "1",
      activeEducation: "1",
      honorNumber: 0,
      items: [],
      createLoading: false,
      idPhotos: [], // 身份证照片
      oneEducation: {},
      twoEducation: {},
      threeEducation: {},
      fourEducation: {},
      fiveEducation: {},
      sixEducation: {},
      honor: {},
      importHeaders: { token: sessionStorage.getItem("Token") }, // 上传的请求头部
      fileLabelMemo: "", // 文件备注
      specialFileId: 0, // 特殊文件的ID
      permanentAddress: address,
      url: configDate.url, // 文件上传地址
      education: configDate.education, // 选择学历
      choiceNationality: configDate.country, // 选择国籍
      isbuyHouse: configDate.GanZhou, // 城中心房
      nation: configDate.nation, // 选择民族
      startDate: new Date().toISOString().substr(0, 10), // 选择证件有效开始日期
      endDate: new Date().toISOString().substr(0, 10), // 选择证件有效结束日期
      entryDate: new Date().toISOString().substr(0, 10), // 入职时间
      socialSecurityDate: new Date().toISOString().substr(0, 10), // 缴纳社保时间
      birthdayDate: new Date().toISOString().substr(0, 10), // 生日日期
      isEditPersonalInformation: false, // 是否点击编辑
      displayMenuConditionStartDate: false, // 菜单控制显示证件有效开始日期
      displayMenuConditionEndDate: false, // 菜单控制显示证件有效结束日期
      displayMenuResumeStartDate: false, // 菜单控制显示工作履历开始日期
      displayMenuResumeEndDate: false, // 菜单控制显示工作履历结束日期
      displayMenuEntryDate: false, // 菜单控制显示入职日期
      displayMenuSocialSecurityDate: false, // 菜单控制显示缴纳社保日期
      displayMenuBirthdayDate: false, // 菜单控制显示生日日期
      overstepEducation: false, // 是否禁用添加学历按钮
      diplomaFile: [], // 最高学历证书附件
      academicRecordFormFile: [], // 最高学历备案表附件
      academicDegreeFile: [], // 最高学位附件
      socialSecurityFile: [], // 社保附件
      personnelTypeFile: [], // 人才类型附件
      wordFile: [], // 免冠工作照
      laborContractFile: [], // 劳动合同
      certificateHonorFile: [], // 荣誉证书
      individualIncomeTax: [], // 个人所得税
      oneEducationFile: [], // 第一学历
      personnelFiles: [],
      remarks: "", // 人才备注
      dialogVisible: false,
      experienceVisible: false,
      form: {
        name: "",
        region: "",
        date: "",
      },
      dialog: false,
      loading: false,
      seePhoto: false,
      addressId: "",
      Ids: "",
      userId: "",
      tableData: {},
      photoFile: {},
      idFile: [],
      academicCertificateFile: {},
      academicCertificateFile2: {},
      honorFile: [],
      path: [],
      account: [],
      clobs: {
        content: "",
      },
      talentType: [
        { name: "A+类（产业领军人才）", id: "value1" },
        { name: "A类（国内外顶尖人才 ）", id: "value2" },
        { name: "B类（国家级高层次人才）", id: "value3" },
        { name: "C类（省级高层次人才 ）", id: "value4" },
        { name: "D类（市级高层次人才）", id: "value5" },
        { name: "E类（行业急需紧缺人才）", id: "value6" },
        { name: "F类（青年英才）", id: "value7" },
        { name: "G类（产业工匠）", id: "value8" },
      ],
      content: "",
      contentNew: "",
      policyInformation: [], // 人才申请政策的信息
      policyExperience: false, // 政策经历弹窗
      workExperience: false, // 工作经历
      workExperienceData: [],
    };
  },

  created() {
    this.Ids = this.$route.params.id;
    this.userId = sessionStorage.getItem("ID");
    this.getData(); //初始化数据
    this.enterpriseInformationData();
    this.getPolicyExperience();
  },

  methods: {
    getData() {
      let self = this;
      this.$axios
        .get("/talent/detail?id=" + this.$route.params.id)
        .then(function(response) {
          self.personalInformation = response.data.data;
          console.log(self.personalInformation);
          self.personalInformation.enterpriseId =
            response.data.data.talentOccupation.enterpriseId;
          self.birthdayDate = self.personalInformation.birthday.substr(0, 10);
          self.entryDate = self.personalInformation.talentOccupation.hireDate.substr(
            0,
            10
          );
          self.startDate = self.personalInformation.startEffectiveDate.substr(
            0,
            10
          );
          self.endDate = self.personalInformation.endEffectiveDate.substr(
            0,
            10
          );
          self.socialSecurityDate = self.personalInformation.socialSecurityPaymentTime.substr(
            0,
            10
          );
          self.personalInformation.phone = response.data.data.account.contact;
          self.personalInformation.addressId = response.data.data.address.id;
          self.personalInformation.details = response.data.data.address.details;
          self.personalInformation.addressed =
            response.data.data.address.province +
            response.data.data.address.city +
            response.data.data.address.county +
            response.data.data.address.details;
          self.personalInformation.address =
            "中国" +
            " / " +
            response.data.data.address.province +
            " / " +
            response.data.data.address.city +
            " / " +
            response.data.data.address.county;
          if (
            self.personalInformation.educations instanceof Array &&
            self.personalInformation.educations.length > 0
          ) {
            for (
              let i = 0;
              i < self.personalInformation.educations.length - 1;
              i++
            ) {
              // 内层循环,控制比较的次数，并且判断两个数的大小
              for (
                let j = 0;
                j < self.personalInformation.educations.length - 1 - i;
                j++
              ) {
                // 白话解释：如果前面的数大，放到后面(当然是从小到大的冒泡排序)
                if (
                  self.personalInformation.educations[j].sort >
                  self.personalInformation.educations[j + 1].sort
                ) {
                  const temp = self.personalInformation.educations[j];
                  self.personalInformation.educations[j] =
                    self.personalInformation.educations[j + 1];
                  self.personalInformation.educations[j + 1] = temp;
                }
              }
            }
          }
          self.personalInformation.educations.forEach((item, index) => {
            switch (index) {
              case 0:
                self.oneEducation = item;
                break;
              case 1:
                self.twoEducation = item;
                break;
              case 2:
                self.threeEducation = item;
                break;
              case 3:
                self.fourEducation = item;
                break;
              case 4:
                self.fiveEducation = item;
                break;
              case 5:
                self.sixEducation = item;
                break;
            }
          });
          self.diplomaFile = []; // 最高学历
          self.academicRecordFormFile = []; // 最高学历备案表
          self.academicDegreeFile = []; // 最高学位
          self.idPhotos = []; //身份证
          self.socialSecurityFile = []; // 社保附件
          self.personnelTypeFile = []; // 人才类型
          self.wordFile = []; // 免冠工作照
          self.laborContractFile = []; // 劳动合同
          self.oneEducationFile = []; // 第一学历文件
          self.individualIncomeTax = []; // 个人所得税
          self.personalInformation.fileIds = [];
          for (let i = 0; i < response.data.data.files.length; i++) {
            let item = response.data.data.files[i];
            self.personalInformation.fileIds.push(item.id);
            switch (item.memo) {
              case "人才类型附件":
                self.personnelTypeFile.push(item);
                break;
              case "免冠工作照":
                self.wordFile.push(item);
                break;
              case "身份证":
                self.idPhotos.push(item);
                break;
              case "社保附件":
                self.socialSecurityFile.push(item);
                break;
              case "最高学历证书":
                self.diplomaFile.push(item);
                self.oneEducationFile.push(item);
                break;
              case "最高学历电子信息备案表":
                self.academicRecordFormFile.push(item);
                self.oneEducationFile.push(item);
                break;
              case "最高学位证书":
                self.academicDegreeFile.push(item);
                self.oneEducationFile.push(item);
                break;
              case "劳动合同":
                self.laborContractFile.push(item);
                break;
              case "个人所得税":
                self.individualIncomeTax.push(item);
                break;
              case "荣誉证书":
                //self.honorFile.push(response.data.data.files[i]);
                break;
              default:
                break;
            }
          }
          console.log(self.personnelTypeFile);
          self.idPhotos.forEach((item) => {
            item.path = configDate.fileurl + item.path;
          });
          self.items = [];
          self.personalInformation.honors.forEach((item, index) => {
            if (index === 0) {
              self.honor = item;
            }
            self.items.push({
              name: item.honorName,
              id: item.id,
            });
          });
          if (!!response.data.data.clobs) {
            response.data.data.clobs.forEach((item, index) => {
              if (index === 0) {
                self.clobs = item;
              }
            });
          }
          if (self.personalInformation.honors) {
            self.honorNumber = self.personalInformation.honors.length;
          }
          self.personalInformation.resumes.forEach((item) => {
            item.displayMenuResumeStartDate = false;
            item.displayMenuResumeEndDate = false;
            item.resumeStartDate = item.startDate;
            item.resumeEndDate = item.endDate;
          });
          self.personalInformation.educations.forEach((item) => {
            item.diplomaFile = []; // 学历证书附件
            item.academicRecordFormFile = []; // 学历备案表附件
            item.academicDegreeFile = []; // 学位证书
            item.files.forEach((fileItem) => {
              switch (fileItem.memo) {
                case "学历证书":
                  item.diplomaFile.push({
                    id: fileItem.id,
                    name: fileItem.name,
                    url: configDate.fileurl + fileItem.path,
                  });
                  break;
                case "学历电子信息备案表":
                  item.academicRecordFormFile.push({
                    id: fileItem.id,
                    name: fileItem.name,
                    url: configDate.fileurl + fileItem.path,
                  });
                  break;
                case "学位证书":
                  item.academicDegreeFile.push({
                    id: fileItem.id,
                    name: fileItem.name,
                    url: configDate.fileurl + fileItem.path,
                  });
                  break;
              }
            });
          });
        })
        .catch((error) => {
          console.error(error);
        });
    },

    // 查找已认证的单位
    enterpriseInformationData() {
      const self = this;
      self.enterpriseName = [];
      this.$axios
        .post("/enterprise/condition", {
          current: 1,
          size: 100,
          statuses: [21],
        })
        .then(function(response) {
          if (response.data.status === 200) {
            response.data.data.records.forEach((item) => {
              self.enterpriseName.push(item);
            });
          }
        })
        .catch(function(error) {
          console.error(error);
        });
    },

    //获取单位id
    getEnterpriseId(selVal) {
      console.log(selVal);
    },

    // 返回上一页
    back() {
      this.$router.push({ path: "/selectionlist" });
    },

    // 政策经历
    setPolicyExperience(time) {
      let date = new Date(time);
      return (
        date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate()
      );
    },

    // 获取政策经历
    getPolicyExperience() {
      // 获取政策经历
      this.$axios
        .get("/affair/page", {
          params: {
            userId: this.Ids,
            current: 1,
            size: 99,
            currentStatus: 21,
          },
        })
        .then((res) => {
          console.log(res);
          if (res.data.status === 200) {
            this.policyInformation = res.data.data.records;
            let policyAffairId = "";
            let policyAffair = res.data.data.records;
            this.policyInformation.forEach((item) => {
              policyAffairId = item.id;
              this.getQueryFundNotes(policyAffairId, policyAffair);
            });
          }
        });
    },

    // 查询资金备注
    getQueryFundNotes(id, policyAffair) {
      this.$axios.get("/comments?policyAffairId=" + id).then((response) => {
        if (response.data.status === 200) {
          response.data.data.forEach((item) => {
            policyAffair.forEach((affairItem) => {
              if (item.affairId === affairItem.id) {
                this.policyInformation.forEach((policyItem) => {
                  if (policyItem.policyId === affairItem.policyId) {
                    policyItem.comment = item.comment;
                  }
                });
              }
            });
          });
          console.log(this.policyInformation);
        }
      });
    },

    // 回到首页
    getHome() {
      let newPage = this.$router.resolve({
        name: "EntryStoma",
      });
      window.open(newPage.href, "_blank");
    },

    // 点击编辑
    openEditPersonalInformation() {
      this.isEditPersonalInformation = true;
    },

    // 学历
    handleSelect(key) {
      this.activeEducation = key;
    },

    // 点击选择查看荣誉证书
    selectCertificate(item) {
      this.personalInformation.honors.forEach((honorItem) => {
        if (item.id === honorItem.id) {
          this.honor = honorItem;
        }
      });
    },

    // 下载文件
    getDownloadFile(item) {
      console.log(item);
      let path =
        item.memo === "身份证" ? item.path : configDate.fileurl + item.path;
      const link = document.createElement("a");
      link.style.display = "none";
      link.href = path;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },

    // 管理员审核
    review(num) {
      let self = this;
      self.keeps();
      if (num === 20) {
        self.dialogVisible = true;
        return false;
      }

      let status = num === 21 ? "确定要申请通过吗?" : "确定要驳回申请吗?";
      self
        .$confirm(status, "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
        .then(() => {
          self.$axios
            .put("/talent/check", {
              handlerId: self.userId,
              status: num,
              talentId: self.Ids,
            })
            .then((response) => {
              if (response.data.status === 200) {
                self.$root.success("办理成功");
                self.getData();
                self.$router.push("/selectionlist");
              } else {
                self.$root.warn("办理失败");
              }
            })
            .catch((error) => {
              console.error(error);
            });
        })
        .catch(() => {
          self.$root.warn("取消办理");
        });
    },

    //  管理员驳回
    requestReject() {
      let self = this;
      this.$axios
        .put("/talent", {
          id: self.$route.params.id,
          memo: self.contentNew,
          status: 20,
        })
        .then((response) => {
          if (response.data.status === 200) {
            self.$root.success("办理成功");
            self.dialogVisible = false;
            self.getData();
          } else {
            self.$root.warn("办理失败");
            self.dialogVisible = false;
          }
        })
        .catch((error) => {
          self.dialogVisible = false;
          console.error(error);
        });
    },

    //  保存文件
    keeps() {
      const self = this;
      self.personalInformation.clobs.push(self.clobs);
      let data = {
        id: self.personalInformation.id,
        clobs: self.personalInformation.clobs,
        type: self.personalInformation.type,
      };
      self.$axios
        .put("/talent", data)
        .then(function(response) {
          if (response.data.status === 200) {
            self.$root.success("保存备注成功！");
            self.getData();
          } else {
            self.$root.warn("保存备注失败");
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },

    //  查看文件
    getFile(path) {
      this.seePhoto = true;
      let pdfFile = path.split(".");
      if (pdfFile[1] === "pdf") {
        //window.open(configDate.pdffileurl + path,"_blank");
        this.pdf = configDate.pdffileurl + path;
        this.addressId = "";
        this.oWidth = "70%";
      } else {
        this.pdf = "";
        this.oWidth = "650px";
        this.addressId = configDate.fileurl + path;
      }
    },

    // 显示身份证
    getIdFile(path) {
      this.pdf = "";
      this.seePhoto = true;
      this.addressId = path;
      this.oWidth = "500px";
    },

    // 编辑提交
    onSubmitPersonalInformation() {
      const self = this;
      let address = [];
      address.push({
        province: self.personalInformation.addresses[1],
        city: self.personalInformation.addresses[2],
        county: self.personalInformation.addresses[3],
        id: self.personalInformation.addressId,
        details: self.personalInformation.details,
      });
      if (address[0].city === undefined) {
        self.personalInformation.addresses[0].details =
          self.personalInformation.details;
      } else {
        self.personalInformation.addresses = address;
      }
      self.personalInformation.occupation = {
        ownerId: self.$route.params.id,
        enterpriseId: self.personalInformation.enterpriseId, // 单位id
        position: self.personalInformation.talentOccupation.position, //所在公司职务
        rankCategory: self.personalInformation.talentOccupation.rankCategory, //职称类别
        hireDate: self.personalInformation.talentOccupation.hireDate, // 入职时间
        id: self.personalInformation.talentOccupation.id,
        rankLevel: self.personalInformation.talentOccupation.rankLevel, // 是否省外引进
        skillCategory: self.personalInformation.talentOccupation.skillCategory, // 从事专业领域
      };
      let remarks = [];
      remarks.push(self.clobs);
      self.personalInformation.clobs = remarks;

      if (!!self.personalInformation.honors) {
        self.personalInformation.honors.forEach((item) => {
          if (!!item.files) {
            item.files.forEach((filesItem) => {
              filesItem.status = 0;
            });
          }
        });
      }
      if (!!self.personalInformation.educations) {
        self.personalInformation.educations.forEach((item) => {
          if (!!item.files) {
            item.files.forEach((filesItem) => {
              filesItem.status = 0;
            });
          }
        });
      }
      this.$axios
        .put("/talent", self.personalInformation)
        .then((response) => {
          if (response.data.status === 200) {
            self.$notify({
              title: "成功",
              message: "更新成功",
              type: "success",
            });
            self.activeIndex = self.activeEducation = "1";
            self.getData();
            self.isEditPersonalInformation = false;
          } else {
            self.$notify({
              title: "失败",
              message: "更新失败",
              type: "warn",
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    // 取消编辑
    getCancelEditPersonalInformationDialog() {
      this.activeIndex = this.activeEducation = "1";
      this.getData();
      this.isEditPersonalInformation = false;
    },

    // 选择生日日期
    onIsSaveBirthdayDateCondition(num) {
      this.displayMenuBirthdayDate = false;
      if (num === 1) {
        this.birthdayDate = this.personalInformation.birthday.substr(0, 10);
      } else {
        this.personalInformation.birthday = this.birthdayDate;
      }
    },

    // 选择证件日期
    onIsSaveIDDateCondition(num) {
      switch (num) {
        case 0:
          this.displayMenuConditionStartDate = false;
          this.personalInformation.startEffectiveDate = this.startDate;
          break;
        case 1:
          this.displayMenuConditionStartDate = false;
          this.startDate = this.personalInformation.startEffectiveDate.substr(
            0,
            10
          );
          break;
        case 2:
          this.displayMenuConditionEndDate = false;
          this.personalInformation.endEffectiveDate = this.endDate;
          break;
        case 3:
          this.displayMenuConditionEndDate = false;
          this.endDate = this.personalInformation.endEffectiveDate.substr(
            0,
            10
          );
          break;
      }
    },

    // 入职时间
    onIsSaveEntryDateCondition(num) {
      this.displayMenuEntryDate = false;
      if (num === 1) {
        this.entryDate = this.personalInformation.talentOccupation.hireDate.substr(
          0,
          10
        );
      } else {
        this.personalInformation.talentOccupation.hireDate = this.entryDate;
      }
    },

    // 缴纳社保时间
    onIsSaveSocialSecurityDateCondition(num) {
      this.displayMenuSocialSecurityDate = false;
      if (num === 1) {
        this.socialSecurityDate = this.personalInformation.socialSecurityPaymentTime.substr(
          0,
          10
        );
      } else {
        this.personalInformation.socialSecurityPaymentTime = this.socialSecurityDate;
      }
    },

    // 添加工作履历
    onAddWorkResume() {
      this.personalInformation.resumes.push({
        companyName: "",
        startDate: "",
        endDate: "",
        jobTitle: "",
        introducePlace: "",
        professionalField: "",
        isAdd: true,
        displayMenuResumeStartDate: false,
        displayMenuResumeEndDate: false,
        resumeStartDate: "",
        resumeEndDate: "",
      });
      console.log(this.personalInformation.resumes);
    },

    // 删除工作履历
    onDeleteWorkResume(item, index) {
      this.$confirm("删除后无法恢复，确认删除吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          if (item.isAdd) {
            this.personalInformation.resumes.splice(index, 1);
          } else {
            this.$axios
              .delete("/tResume?idList=" + item.id)
              .then((response) => {
                if (response.data.data === true) {
                  this.personalInformation.resumes.splice(index, 1);
                }
              });
          }
        })
        .catch(() => {});
    },

    // 添加学历信息
    onAddEducationInformation() {
      let self = this;
      let date = { ownerId: this.Ids };
      this.$axios.post("/talent/education", date).then((response) => {
        if (response.data.status === 200) {
          self.personalInformation.educations.push({
            academic: "", //  最高学历
            school: "", //毕业学校
            major: "", //专业
            id: response.data.data,
            graduateTime: "", //毕业日期
            diplomaCode: "", //毕业证书编号
            isStudyAbroad: false, // 是否国外学历
            ownerId: date,
            schoolSystem: "", // 学制
            type: "全日制", // 是否全日制
            sort: this.personalInformation.educations.length + 1, // 排序
            /**
             * 学历证书附件数组
             */
            diplomaFile: [],
            /**
             * 学历电子注册备案表
             */
            academicRecordFormFile: [],
            /**
             * 学位证书
             */
            academicDegreeFile: [],
          });
          if (self.personalInformation.educations.length > 4) {
            self.overstepEducation = true;
          }
        } else {
          self.$notify({
            title: "添加学历失败",
            type: "warning",
          });
        }
      });
    },

    // 删除学历信息
    onDeleteEducationInformation(item, index) {
      this.$confirm("删除后无法恢复，确认删除吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          if (this.personalInformation.educations.length < 6) {
            this.overstepEducation = false;
          }
          this.$axios
            .delete("/talent/education?id=" + item.id)
            .then((response) => {
              if (response.data.status === 200) {
                this.personalInformation.educations.splice(index, 1);
              }
            });
        })
        .catch(() => {});
    },

    // 添加荣誉证书
    onAddCertificateHonor() {
      let date = { talentId: this.Ids };
      this.$axios.post("/honor", date).then((response) => {
        if (response.data.code === 0) {
          this.personalInformation.honors.push({
            number: "honors" + this.personalInformation.honors.length,
            honorName: "",
            implementationDepartment: "",
            awardYear: "",
            rewardLevel: "",
            certificateHonorFile: [],
            id: response.data.data,
          });
        }
      });
    },

    // 删除荣誉证书
    onDeleteCertificateHonor(item, index) {
      this.$confirm("删除后无法恢复，确认删除吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$axios.delete("/honor?idList=" + item.id).then((response) => {
            if (response.data.data === true) {
              this.personalInformation.honors.splice(index, 1);
            }
          });
        })
        .catch(() => {});
    },

    // 选择地址
    handleAddresses(value) {
      this.personalInformation.addresses = value;
    },

    // 文件上传成功
    uploadSuccessFile(response) {
      const self = this;
      if (response.status === 200) {
        let data = {
          id: response.data.id,
          memo: self.fileLabelMemo,
          ownerId: self.specialFileId,
        };
        this.$axios.put("/file/record", data).then(function(res) {
          if (res.data.status === 200) {
            if (res.data.data !== "更新失败") {
              self.$message({
                message: "上传成功",
                type: "success",
              });
              if (self.specialFileId === undefined) {
                self.personalInformation.fileIds.push(response.data.id);
              }
            } else {
              self.$message({
                message: "上传失败",
                type: "warning",
              });
            }
          }
        });
      } else {
        self.$message({
          showClose: true,
          message: "上传失败",
          type: "warning",
        });
      }
    },

    // 控制上传文件格式及大小
    uploadBeforeFile(file) {
      const isJPG = file.type === "image/png";
      const isLt2M = file.size / 1024 / 1024 < 5;

      if (!isJPG) {
        this.$message.error("图片只能是 png 格式!");
        return false;
      }
      if (!isLt2M) {
        this.$message.error("图片大小不能超过 5MB!");
        return false;
      }
    },

    // 控制上传文件PDF格式及大小
    uploadBeforeFDFFile(file) {
      const isPDF = file.type === "application/pdf";
      const isLt2M = file.size / 1024 / 1024 < 7;

      if (isPDF) {
      } else {
        this.$message.error("请上传pdf文件");
        return false;
      }
      if (!isLt2M) {
        this.$message.error("图片大小不能超过 7MB!");
        return false;
      }
    },

    //删除文件
    handleRemove(file) {
      let self = this;
      self.$axios.delete("/attach?id=" + file.id).then(function(res) {
        if (res.data.status === 200) {
          self.$notify({
            title: "删除文件成功",
            type: "warning",
          });
        } else {
          self.$notify({
            title: "删除文件失败",
            type: "warning",
          });
        }
      });
    },

    // 删除文件前
    beforeRemove(file) {
      let self = this;
      let id;
      if (file.id) {
        id = file.id;
      }
      if (file.response) {
        id = file.response.data.id;
      }
      if (!id) return;
      this.$axios.delete("/file/delete?id=" + id).then((response) => {
        if (response.data.status === 200) {
          let arr = self.personalInformation.files.indexOf(id);
          self.personalInformation.files.splice(arr, 1);
        } else {
          self.$notify({
            title: "删除文件失败",
            type: "warning",
          });
        }
      });
    },

    //  点击查看文件
    handlePreview(file) {
      console.log(file);
      // this.seePhoto = true;
      // this.addressId = configDate.fileurl + file.path
      let pdfFile = path.split(".");
      if (pdfFile[1] === "pdf") {
        this.seePhoto = true;
        this.pdf = configDate.pdffileurl + path;
        this.addressId = "";
        //window.open(configDate.pdffileurl + path,"_blank");
      } else {
        this.seePhoto = true;
        this.addressId = configDate.fileurl + path;
        this.pdf = "";
      }
    },

    // 给文件加上备注
    setLabelMemo(labelName, id) {
      this.fileLabelMemo = labelName;
      this.specialFileId = id;
    },
  },
};
</script>

<style lang="less" scoped>
* {
  color: #000000;
}

.nav {
  margin-top: 12px;
  color: #2a3746;

  a {
    color: #2a3746;
  }
  margin-right: 8px;
}

.text-content {
  text-align: left;
  margin: 0 8px;
  color: #000000;
  font-size: 16px;

  span {
    color: #8a8f97;
  }

  .content {
    color: #2a3746;
  }
}

.card-height {
  margin: 0 8px;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;

  .id-file {
    display: inline-block;
    min-width: 160px;
    min-height: 48px;
    margin: 0 8px;

    .id-photo {
      width: 120px;
      display: inline-block;
    }

    .down {
      float: right;
      margin-top: 48px;
    }
  }

  .file {
    min-width: 240px;
    height: 48px;

    .see-file {
      min-width: 160px;
    }
  }

  .file-card {
    display: inline-block;
    vertical-align: top;
    width: 256px;
  }

  .upload-demo {
    width: 240px;
    border: 1px solid #0c94ea;
    border-radius: 8px;
    padding: 8px;

    .file-button {
      color: #0c94ea;
    }

    .file-name {
      text-align: left;

      span {
        color: red;
      }
    }
  }
}

.file-card-height {
  margin: 0 8px;
  min-width: 200px;
  width: 100%;

  .file-card {
    width: 100%;
    vertical-align: top;
  }

  .upload-demo {
    width: 100%;
    border: 1px solid #0c94ea;
    border-radius: 8px;
    padding: 8px;

    .file-button {
      color: #0c94ea;
      display: inline-block;
      margin-left: 24px;
    }

    .file-name {
      display: inline-block;
      margin-left: 24px;
    }
  }
}

.authentication-information {
  color: #0c94ea;
  font-size: 18px;
  font-weight: bold;
  text-align: left;
  height: 48px;
  margin-left: 24px;
  padding-top: 2px;
  line-height: 48px;
}

.authentication-resume {
  color: #0c94ea;
  font-size: 18px;
  font-weight: bold;
  text-align: left;
  height: 48px;
  padding-top: 2px;
  line-height: 48px;
}

.honor {
  width: 100%;
  height: 48px;
  font-weight: bold;
  padding: 0 8px;
  color: #ffffff;
  line-height: 48px;
  font-size: 16px;
  background-color: #0c94ea;
  text-align: left;
}

.talents-type {
  text-align: left;

  .remarks {
    background-color: #f5f5f5;
    border: 1px #8a8f97 dashed;
  }
}

.input-style,
.select-style {
  color: #000000;
  min-width: 60%;
  margin: 0 8px;
  padding: 4px 8px;
  border-radius: 4px;
  border: 1px solid #8a8f97;
}

.textarea-style {
  color: #000000;
  min-width: 100%;
  margin: 0 8px;
  padding: 4px 8px;
  border-radius: 4px;
  border: 1px solid #8a8f97;
}

input:focus {
  //border: 1px solid #0c94ea;
  //box-shadow: inset 0 0 0 1000px #FFFFFF!important;

  background: #ffffff;
  border: 1px solid #2da7e0;
  /* 解决input按钮自动填充后 浏览器渲染的默认背景问题 */
  box-shadow: inset 0 0 0 1000px #ffffff !important;
}

.el-cascader::placeholder {
  color: #000000;
  text-align: center;
}

.address-style {
  width: 30%;
  color: #000000;
}

.headtit {
  background: #1090e7;
  height: 50px;
  text-align: left;
  line-height: 50px;
  padding: 0 0 0 16px;
  color: #fff;
  font-size: 16px;
}

.button {
  overflow: hidden;
  margin: 32px 0;
}

.basicCoxs {
  overflow: hidden;
  text-align: left;

  .cailxx {
    span {
      margin: 1px;
    }
  }

  .fujianx {
    margin-top: 20px;

    span {
      width: 80%;
      height: 35px;
      background: #fff;
      padding: 0 16px;
      border: 1px #1090e7 solid;
      text-align: center;
      cursor: pointer;
      min-width: 280px;
    }
  }

  span {
    background: #f7f7f8;
    font-size: 14px;
    display: block;
    min-height: 35px;
    text-align: left;
    padding: 0 16px;
    line-height: 33px;
    overflow: hidden;
    width: 80%;
    margin: 4px 8px;
    min-width: 280px;
    border: 1px solid #e4e4e4;
  }
}

.size-div {
  font-size: 14px;
  margin: 16px 0 0 12px;
}

.textType {
  font-size: 14px;
}

.text {
  color: #ffffff;
}

.policy-resume {
  display: block;
  width: 100%;
  min-height: 80px;
  padding: 8px 16px;
  border-radius: 8px;
}
</style>
